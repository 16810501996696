import { DTOExporterPropertyDefinitionCode } from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

export class ExporterPropertyDefinitionCode extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "String"

  language: string

  defaultValue: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionCode) {
    super(model)

    this.language = model.language
    this.defaultValue = model.default
  }
}

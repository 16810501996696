//
//  SDKThemeUtilities.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { UnreachableCaseError } from "../../utils/UnreachableCaseError"
import { TokenType } from "../enums/SDKTokenType"
import { TokenOrigin } from "../support/SDKTokenOrigin"
import { BlurToken } from "../tokens/SDKBlurToken"
import { BorderToken } from "../tokens/SDKBorderToken"
import { ColorToken } from "../tokens/SDKColorToken"
import {
  BorderWidthToken,
  DimensionToken,
  DurationToken,
  FontSizeToken,
  LetterSpacingToken,
  LineHeightToken,
  OpacityToken,
  ParagraphSpacingToken,
  RadiusToken,
  SizeToken,
  SpaceToken,
  ZIndexToken,
} from "../tokens/SDKDimensionToken"
import { GradientToken } from "../tokens/SDKGradientToken"
import { ShadowToken } from "../tokens/SDKShadowToken"
import {
  FontFamilyToken,
  FontWeightToken,
  ProductCopyToken,
  StringToken,
} from "../tokens/SDKStringToken"
import { TextCaseToken } from "../tokens/SDKTextCaseToken"
import { TextDecorationToken } from "../tokens/SDKTextDecorationToken"
import { Token } from "../tokens/SDKToken"
import { AnyToken } from "../tokens/SDKTokenValue"
import { TypographyToken } from "../tokens/SDKTypographyToken"
import { VisibilityToken } from "../tokens/SDKVisibilityToken"

import { cloneDeep } from "lodash"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class ThemeUtilities {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Helpers

  /** Creates duplicate of the token */
  static replicateTokenAsThemePrefabWithoutValue(
    token: Token,
    themeId: string,
    origin: TokenOrigin | null,
    versionId: string
  ): AnyToken {
    let replica: AnyToken

    switch (token.tokenType) {
      case TokenType.blur:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BlurToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.border:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BorderToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.color:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ColorToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.gradient:
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new GradientToken(versionId, {} as any, [], null, [], [])
        break
      case TokenType.dimension:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new DimensionToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.size:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new SizeToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.space:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new SpaceToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.opacity:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new OpacityToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.fontSize:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontSizeToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.lineHeight:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new LineHeightToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.letterSpacing:
        replica = new LetterSpacingToken(
          versionId,
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          {} as any,
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          null,
          null,
          [],
          []
        )
        break
      case TokenType.paragraphSpacing:
        replica = new ParagraphSpacingToken(
          versionId,
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          {} as any,
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          null,
          null,
          [],
          []
        )
        break
      case TokenType.borderWidth:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BorderWidthToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.radius:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new RadiusToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.duration:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new DurationToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.zIndex:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ZIndexToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.shadow:
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ShadowToken(versionId, {} as any, [], null, [], [])
        break
      case TokenType.string:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new StringToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.productCopy:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ProductCopyToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.fontFamily:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontFamilyToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.fontWeight:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontWeightToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.typography:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new TypographyToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.textCase:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new TextCaseToken(versionId, {} as any, null, null, [], [])
        break
      case TokenType.textDecoration:
        replica = new TextDecorationToken(
          versionId,
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          {} as any,
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          null,
          null,
          [],
          []
        )
        break
      case TokenType.visibility:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new VisibilityToken(versionId, {} as any, null, null, [], [])
        break
      default:
        throw new UnreachableCaseError(token.tokenType, "Unsupported type:")
    }

    replica.id = token.id
    replica.idInVersion = token.idInVersion
    replica.brandId = token.brandId
    replica.themeId = themeId // Assign just-created theme
    replica.designSystemVersionId = token.designSystemVersionId
    replica.name = token.name
    replica.description = token.description
    replica.tokenType = token.tokenType
    replica.origin = origin ?? null // Re-direct origin to the other origin
    replica.parentGroupId = token.parentGroupId
    replica.sortOrder = token.sortOrder
    replica.properties = token.properties
    replica.propertyValues = token.propertyValues
    replica.createdAt = token.createdAt
    replica.updatedAt = token.updatedAt

    // No replication of value - value will get replaced by the override

    return replica
  }

  /** Creates duplicate of the token */
  static replicateTokenWithoutValue<T extends AnyToken>(token: T): T {
    let replica: AnyToken

    switch (token.tokenType) {
      case TokenType.blur:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BlurToken("", {} as any, null, null, [], [])
        break
      case TokenType.border:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BorderToken("", {} as any, null, null, [], [])
        break
      case TokenType.color:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ColorToken("", {} as any, null, null, [], [])
        break
      case TokenType.gradient:
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new GradientToken("", {} as any, [], null, [], [])
        break
      case TokenType.dimension:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new DimensionToken("", {} as any, null, null, [], [])
        break
      case TokenType.size:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new SizeToken("", {} as any, null, null, [], [])
        break
      case TokenType.space:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new SpaceToken("", {} as any, null, null, [], [])
        break
      case TokenType.opacity:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new OpacityToken("", {} as any, null, null, [], [])
        break
      case TokenType.fontSize:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontSizeToken("", {} as any, null, null, [], [])
        break
      case TokenType.lineHeight:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new LineHeightToken("", {} as any, null, null, [], [])
        break
      case TokenType.letterSpacing:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new LetterSpacingToken("", {} as any, null, null, [], [])
        break
      case TokenType.paragraphSpacing:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ParagraphSpacingToken("", {} as any, null, null, [], [])
        break
      case TokenType.borderWidth:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new BorderWidthToken("", {} as any, null, null, [], [])
        break
      case TokenType.radius:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new RadiusToken("", {} as any, null, null, [], [])
        break
      case TokenType.duration:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new DurationToken("", {} as any, null, null, [], [])
        break
      case TokenType.zIndex:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ZIndexToken("", {} as any, null, null, [], [])
        break
      case TokenType.shadow:
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ShadowToken("", {} as any, [], null, [], [])
        break
      case TokenType.string:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new StringToken("", {} as any, null, null, [], [])
        break
      case TokenType.productCopy:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new ProductCopyToken("", {} as any, null, null, [], [])
        break
      case TokenType.fontFamily:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontFamilyToken("", {} as any, null, null, [], [])
        break
      case TokenType.fontWeight:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new FontWeightToken("", {} as any, null, null, [], [])
        break
      case TokenType.typography:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new TypographyToken("", {} as any, null, null, [], [])
        break
      case TokenType.textCase:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new TextCaseToken("", {} as any, null, null, [], [])
        break
      case TokenType.textDecoration:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new TextDecorationToken("", {} as any, null, null, [], [])
        break
      case TokenType.visibility:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        replica = new VisibilityToken("", {} as any, null, null, [], [])
        break
      default:
        // TODO:fix-sdk-eslint
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new UnreachableCaseError(token.tokenType, "Unsupported type:")
    }

    replica.id = token.id
    replica.idInVersion = token.idInVersion
    replica.brandId = token.brandId
    replica.themeId = token.themeId
    replica.collectionId = token.collectionId
    replica.designSystemVersionId = token.designSystemVersionId
    replica.name = token.name
    replica.description = token.description
    replica.tokenType = token.tokenType
    replica.tokenPath = token.tokenPath
    replica.origin = token.origin
    replica.parentGroupId = token.parentGroupId
    replica.sortOrder = token.sortOrder
    replica.properties = token.properties
    replica.propertyValues = token.propertyValues
    replica.createdAt = token.createdAt
    replica.updatedAt = token.updatedAt

    // No replication of value - value will get replaced by the override

    return replica as T
  }

  /** Creates duplicate of the token */
  static replicateTokenWithValue<T extends AnyToken>(token: T): T {
    const replica = this.replicateTokenWithoutValue(token)

    replica.value = cloneDeep(token.value)
    return replica
  }
}

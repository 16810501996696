//
//  FigmaStructureModel.ts
//  Supernova SDK
//
import { DTOFigmaNodeStructure } from "@supernova-studio/client"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type FigmaStructureModel = {
  assetsInFile: {
    componentSets: number
    components: number
    frames: number
  }
  designSystemVersionId: string
  id: string
  origin: {
    sourceId: string
  }
  persistentId: string
}

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export enum FigmaStructureImportState {
  failed = "Failed",
  inProgress = "InProgress",
  success = "Success",
}

export class FigmaStructure {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  sourceId: string

  createdAt: Date

  updatedAt: Date

  state: FigmaStructureImportState

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaNodeStructure) {
    this.id = model.id
    this.sourceId = model.sourceId
    this.createdAt = model.createdAt
    this.updatedAt = model.updatedAt
    this.state = parseImportState(model.importState)
  }
}

function parseImportState(
  state: DTOFigmaNodeStructure["importState"]
): FigmaStructureImportState {
  switch (state) {
    case "Failed":
      return FigmaStructureImportState.failed
    case "InProgress":
      return FigmaStructureImportState.inProgress
    case "Success":
      return FigmaStructureImportState.success
    default:
      return FigmaStructureImportState.success
  }
}

//
//  FigmaStructureDetailModel.ts
//  Supernova SDK
//
import { DTOFigmaNodeStructureDetail } from "@supernova-studio/client"

import { FigmaFrame } from "./SDKFigmaFrame"
import { FigmaStructure } from "./SDKFigmaStructure"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class FigmaStructureDetail extends FigmaStructure {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  rootNode: FigmaFrame

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOFigmaNodeStructureDetail) {
    super(model)
    this.rootNode = new FigmaFrame(model.rootNode)
  }
}

import { DTOExporterPropertyDefinition } from "@supernova-studio/client"

export type ExporterPropertyType =
  | "Array"
  | "Boolean"
  | "Code"
  | "Enum"
  | "Number"
  | "Object"
  | "String"

export class ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // Public properties

  key: string

  title: string

  description: string

  category?: string

  dependsOn?: Record<string, boolean>

  type: ExporterPropertyType

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // Constructor

  constructor(model: DTOExporterPropertyDefinition) {
    this.key = model.key
    this.title = model.title
    this.description = model.description
    this.category = model.category
    this.dependsOn = model.dependsOn
    this.type = model.type
  }
}

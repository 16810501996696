import { DTOExporterPropertyDefinitionArray } from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

export class ExporterPropertyDefinitionArray extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "Array"

  defaultValues: string[]

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionArray) {
    super(model)
    this.defaultValues = model.default
  }
}
